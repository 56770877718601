@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Nunito", "" -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-menu-item-selected {
  background-color: #f2890d !important;
}

.ant-table table {
  font-family: "Nunito";
  font-weight: 600;
}

/* Scrollbar styles for WebKit browsers (Chrome, Safari, Edge) */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 20px;
  background: linear-gradient(180deg, #f39019 100%, #ffffff 3%);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: linear-gradient(180deg, #f39019 100%, #ffffff 3%);
  border: 3px solid #ffffff; /* Creates space around thumb for a more rounded appearance */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #f39019 100%, #ffffff 3%);
}

/* Firefox Scrollbar styles */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #f39019 #f4f4f4;
}

.custom-scrollbar::-moz-scrollbar-track {
  border-radius: 20px;
  background: linear-gradient(180deg, #f39019 100%, #ffffff 3%);
}

.custom-scrollbar::-moz-scrollbar-thumb {
  border-radius: 20px;
  background: linear-gradient(180deg, #f39019 100%, #ffffff 3%);
}

.custom-search-input:focus {
  border-color: #f39019 !important;
  box-shadow: 0 0 0 2px rgba(254, 243, 230, 0.2); /* Optionally, you can add a shadow to emphasize the focus */
}
.custom-search-input:hover {
  border-color: #f39019 !important;
  box-shadow: 0 0 0 2px rgba(254, 243, 230, 0.2); /* Optionally, you can add a shadow to emphasize the focus */
}

.carousel .slick-prev,
.carousel .slick-next {
  color: black;
  margin-left: -20px;
  margin-right: -40px;
  /* Change arrow color */
}

.carousel .slick-prev:hover,
.carousel .slick-next:hover {
  color: black;
  margin-left: -20px;
  margin-right: -40px; /* Maintain color on hover */
}

.carousel .slick-prev::before,
.carousel .slick-next::before {
  color: black;
  margin-left: -20px;
  margin-right: -40px; /* Change arrow icon color */
}
